@font-face {
font-family: '__lexia_91fd5d';
src: url(/_next/static/media/01fbcdbca8d70066-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__lexia_91fd5d';
src: url(/_next/static/media/a5e29e65f979574e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__lexia_Fallback_91fd5d';src: local("Arial");ascent-override: 96.49%;descent-override: 23.07%;line-gap-override: 0.00%;size-adjust: 102.87%
}.__className_91fd5d {font-family: '__lexia_91fd5d', '__lexia_Fallback_91fd5d'
}

